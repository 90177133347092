'use strict'

angular
	.module('cb.maintenance', [])
	.controller('MaintenanceSalesAreaController', ["$scope", "$modal", "MaintenanceService", "StaffService", "NotificationService", "$route", function ($scope, $modal, MaintenanceService, StaffService, NotificationService, $route) {
		$scope.$watch('startAt', function () {
			$scope.getSalesAreas();
		});
		$scope.startAt = 0;
		$scope.limit = 10;


		$scope.init = function () {
			$scope.staffSelected = null;
			$scope.regionSelected = null;
			$scope.GetAllStaffPromise = StaffService.getAllStaff();
			$scope.GetAllStaffPromise.then(function (output) {
				$scope.allStaffList = output.data.StaffList;
			}, function (error) {
				NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});

			$scope.GetAllRegionsPromise = MaintenanceService.getAllRegions();
			$scope.GetAllRegionsPromise.then(function (output) {
				$scope.allRegionList = output.data;
			}, function (error) {
				NotificationService.alert('Regions Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
		};

		$scope.getSalesAreas = function () {
			var data = {};
			data.Pagination = {};
			data.Pagination.StartPage = $scope.startAt;
			data.PageSize = $scope.limit;
			$scope.getSalesAreasPromise = MaintenanceService.getSalesAreas(data);
			$scope.getSalesAreasPromise.then(function (output) {
				console.log(output);
				$scope.Areas = output.data.Areas;
				$scope.AreasNextPage = output.data.PaginationStatus.HasPageForwards;
				$scope.AreasPreviousPage = output.data.PaginationStatus.HasPageBack;
			}, function (error) {
				NotificationService.alert('Areas Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
		};

		$scope.updateRegion = function () {
			var data = { Region: $scope.regionSelected, SalesPerson: $scope.staffSelected };
			$scope.updateRegionPromise = MaintenanceService.updateRegions(data);
			$scope.updateRegionPromise.then(function (output) {
				NotificationService.alert('Region Updated', 'The  region was updated successfully.', 'success', {});
				$route.reload();
			}, function (error) {
				NotificationService.alert('Region Error', 'There was an error updating record, please try again.', 'error', {});
			});
		};
		$scope.openAreaModal = function (area) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editSalesAreaModal.html',
				controller: 'EditSalesAreaController',
				resolve: {
					area: function () {
						return angular.copy(area);
					},
				}
			});

			modalInstance.result.then(function () {
				$scope.getSalesAreas();
			});
		};
		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])
	.controller('CreateNewAreaModalCtrl', ["$scope", "$rootScope", "$modal", "PostcodeService", function ($scope, $rootScope, $modal, PostcodeService) {
		$scope.postcodeList = [];
		$scope.typedPostcode = "";
		$scope.PostcodeInfo = [];
		$scope.getPostcode = function (par) {
			$scope.postcodepromise = PostcodeService.getPostcodesAutocomplete(par);
			$scope.postcodepromise.then(function (out) {
				$scope.postcodeList = out.data.result;
			})
		}
		$scope.getInfo = function (par) {
			$scope.typedPostcode = "";
			$scope.postcodeList = null;
			$scope.PostInfoPromice = PostcodeService.getPostcodeInfo(par);
			$scope.postcodepromise.then(function (out) {
				$scope.PostcodeInfo = out.data.result;
			})
		}
	}])
	.controller('MaintenanceParametersController', ["$scope", "$rootScope", "$modal", "MaintenanceService", "NotificationService", function ($scope, $rootScope, $modal, MaintenanceService, NotificationService) {
		$scope.$watch('startAt', function () {
			$scope.getGlobalParams();
		});

		$scope.startAt = 0;
		$scope.limit = 100;

		$scope.getGlobalParams = function () {
			$scope.GlobalParamError = false;
			var data = {};
			data.Pagination = {};
			data.Pagination.StartPage = $scope.startAt;
			data.Pagination.PageSize = $scope.limit;
			$scope.GetGlobalParamsPromise = MaintenanceService.getGlobalParams(data);
			$scope.GetGlobalParamsPromise.then(function (output) {
				$scope.Parameters = output.data.GlobalParamList;

				$scope.ParametersNextPage = output.data.PaginationStatus.HasPageForwards;
				$scope.ParametersPreviousPage = output.data.PaginationStatus.HasPageBack;
			}, function (error) {
				NotificationService.alert('Parameter Error', 'There was an error retriving parameter.', 'error', {});
				$scope.GlobalParamError = true;
			})
		};

		$scope.deleteParameter = function (param) {
			if (confirm("Are you sure that you want to delete this parameter?")) {
				$scope.DeleteParameterPromise = MaintenanceService.deleteParameter(param.Id);
				$scope.DeleteParameterPromise.then(function (output) {
					NotificationService.alert('Parameter Deleted', 'The parameter was deleted successfully.', 'success', {});
					$scope.getGlobalParams();
				}, function (error) {
					NotificationService.alert('Parameter Delete Error', 'There was an error deleting the parameter.', 'error', {});
				});
			}
		}

		$scope.openParameterModal = function (param) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editParameterModal.html',
				controller: 'EditParameterController',
				resolve: {
					parameter: function () {
						return param;
					},
				}
			});

			modalInstance.result.then(function () {
				$scope.getGlobalParams();
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

	}])
	.controller('EditParameterController', ["$scope", "$rootScope", "parameter", "MaintenanceService", "$modalInstance", "NotificationService", function ($scope, $rootScope, parameter, MaintenanceService, $modalInstance, NotificationService) {
		$scope.editParameter = angular.copy(parameter);

		$scope.save = function () {
			var data = {};
			data.Id = $scope.editParameter.Id;
			data.ParamKey = $scope.editParameter.ParamKey;
			data.ParamValue = $scope.editParameter.ParamValue;

			$scope.GetParameterPromise = MaintenanceService.updateParameter(data);
			$scope.GetParameterPromise.then(function (output) {
				NotificationService.alert('Parameter Updated', 'The parameter was updated successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Parameter Updated Error', 'There was an error updating the parameter, please try again.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('EditSalesAreaController', ["$scope", "$rootScope", "area", "MaintenanceService", "$modalInstance", "NotificationService", "StaffService", function ($scope, $rootScope, area, MaintenanceService, $modalInstance, NotificationService, StaffService) {
		$scope.area = area;
		$scope.newExcludedPostcode = {};

		$scope.fetchPartPostcodes = MaintenanceService.fetchPartPostcodes($scope.area.Id);
		$scope.fetchPartPostcodes.then(function (output) {
			$scope.partPostcodes = output.data;
		}), function (error) {
			NotificationService.alert('Part Postcode Error', 'Failed to fetch prexisting part postcodes.', 'error', {});
		}

		$scope.save = function () {
			$scope.UpdateAreaPromise = MaintenanceService.updateArea(area);
			$scope.UpdateAreaPromise.then(function (output) {
				NotificationService.alert('Area Updated', 'The area was updated successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Area Updated Error', 'There was an error updating the area, please try again.', 'error', {});
			});
		};

		$scope.GetAllStaffPromise = StaffService.getAllStaff();
		$scope.GetAllStaffPromise.then(function (output) {
			$scope.allStaffList = output.data.StaffList;
		}, function (error) {
			NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
		});

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.populateExcludedPostcodes = function () {
			$scope.partPostcodes = [];
			$scope.fetchPartPostcodes = MaintenanceService.fetchPartPostcodes($scope.area.Id);
			$scope.fetchPartPostcodes.then(function (output) {
				$scope.partPostcodes = output.data;
			}), function (error) {
				NotificationService.alert('Part Postcode Error', 'Failed to fetch prexisting part postcodes.', 'error', {});
			}
		}

		$scope.addExcludedPostcode = function () {
			if ($scope.newExcludedPostcode.Start != null && $scope.newExcludedPostcode.End != null) {
				var data = {
					AreaId: $scope.area.Id,
					Start: $scope.newExcludedPostcode.Start,
					End: $scope.newExcludedPostcode.End
				}
				$scope.addPartPostcode = MaintenanceService.addPartPostcode(data)
				$scope.addPartPostcode.then(function () {
					$scope.populateExcludedPostcodes();
					NotificationService.alert('Part Postcode Added', 'The postcode has been updated with the exclusion', 'success', {});
				}), function (error) {
					NotificationService.alert('Part Postcode Error', 'Failed to add the postcode exclusion.', 'error', {});
				}
			}
		}

		$scope.RemoveExcludedPostcodes = function (input) {
			$scope.removePartPostcode = MaintenanceService.removePartPostcode(input);
			$scope.removePartPostcode.then(function (output) {
				$scope.populateExcludedPostcodes();
				NotificationService.alert('Part Postcode Removed', 'The postcode exclusion has been removed', 'success', {});
			}), function (error) {
				NotificationService.alert('Part Postcode Error', 'Failed to remove the postcode exclusion.', 'error', {});
			}
		}
	}])
	.controller('MaintenanceStaffController', ["$scope", "$modal", "StaffService", "NotificationService", "MaintenanceService", "PickListService", function ($scope, $modal, StaffService, NotificationService, MaintenanceService, PickListService) {
		$scope.$watch('startAt', function () {
			$scope.getAllStaff();
		});
		$scope.filter = 'Current';
		$scope.init = function () {
			$scope.GetPickListsPromise = PickListService.getPickListByPickListName(["Department"]);
			$scope.GetPickListsPromise.then(function (output) {
				$scope.DepartmentPickList = output.data.PickListEntries;
			});

			$scope.GetlistOfRolesForStaffPromise = StaffService.getlistOfRolesForStaff();
			$scope.GetlistOfRolesForStaffPromise.then(function (output) {
				$scope.StaffRolesList = output.data.StaffList;
			}, function (error) {
				NotificationService.alert('Staff Roles List Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
			});
			var data = {};
			data.Pagination = {};
			data.Pagination.StartPage = 0;
			data.Pagination.PageSize = 99999;
			$scope.GetlistOfGroupsForStaffPromise = MaintenanceService.getUserGroups(data);
			$scope.GetlistOfGroupsForStaffPromise.then(function (output) {
				$scope.StaffGroupsList = output.data.UserGroupList;
			}, function (error) {
				NotificationService.alert('Staff Group List Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
			});
		};

		$scope.staffPage = 1;
		$scope.staffNextPage = true;

		$scope.getAllStaff = function () {
			$scope.GetAllStaffPromise = StaffService.getAllStaff(true);
			$scope.GetAllStaffPromise.then(function (output) {
				$scope.StaffList = output.data.StaffList;
				var staying = $scope.StaffList.filter(staff => staff.LeavingDate == '0001-01-01T00:00:00');
				var leaving = $scope.StaffList.filter(staff => staff.LeavingDate != '0001-01-01T00:00:00');
				$scope.StaffList = staying.concat(leaving);
				$scope.AreaManagers = $scope.StaffList.filter(staff => staff.Roles.find(role => role.Name.toLowerCase().indexOf('manager') >= 0) ? true : false).map(staff => { return { Id: staff.Id, Name: staff.FirstName + " " + staff.LastName } });
			}, function (error) {
				NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
		};

		$scope.activateStaff = function (id) {
			$scope.ActivateStaffPromise = StaffService.activateStaff(id);
			$scope.ActivateStaffPromise.then(function (output) {
				$scope.getAllStaff();
			}, function (error) {
				NotificationService.alert('Activation Error', 'There was an activating this staff memeber, please try again.', 'error', {});
			});
		};

		$scope.deactivateStaff = function (id) {
			$scope.DeactivateStaffPromise = StaffService.deactivateStaff(id);
			$scope.DeactivateStaffPromise.then(function (output) {
				$scope.getAllStaff();
			}, function (error) {
				NotificationService.alert('Deactivation Error', 'There was an deactivating this staff memeber, please try again.', 'error', {});
			});
		};

		$scope.openStaffEditModal = function (staff) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editStaffModal.html',
				controller: 'EditStaffController',
				resolve: {
					Staff: function () {
						return staff;
					},
					AreaManagerList: function () {
						return $scope.AreaManagers;
					},
					DepartmentPickList: function () {
						return $scope.DepartmentPickList;
					},
					StaffRolesList: function () {
						return $scope.StaffRolesList;
					},
					StaffGroupsList: function () {
						return $scope.StaffGroupsList;
					}
				}
			});
			modalInstance.result.then(function () {
				$scope.getAllStaff();
			});
		};

		$scope.openStaffCreateModal = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'createNewStaffModal.html',
				controller: 'CreateNewStaffController',
				resolve: {
					AreaManagerList: function () {
						return $scope.AreaManagers;
					},
					DepartmentPickList: function () {
						return $scope.DepartmentPickList;
					},
					StaffRolesList: function () {
						return $scope.StaffRolesList;
					}
				}
			});
			modalInstance.result.then(function () {
				$scope.getAllStaff();
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])
	.controller('CreateNewStaffController', ["$scope", "$rootScope", "$modal", "$modalInstance", "StaffService", "MaintenanceService", "NotificationService", "AreaManagerList", "DepartmentPickList", "StaffRolesList", function ($scope, $rootScope, $modal, $modalInstance, StaffService, MaintenanceService, NotificationService, AreaManagerList, DepartmentPickList, StaffRolesList) {
		$scope.Staff = {};
		$scope.Staff.Roles = [];

		$scope.Today = new Date();
		$scope.openPopups = {};
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.AreaManagerList = AreaManagerList;
		$scope.DepartmentPickList = DepartmentPickList;
		$scope.StaffRolesList = StaffRolesList;

		$scope.create = function () {
			$scope.Staff.Username = $scope.Staff.Email;
			$scope.CreateStaffPromise = StaffService.createStaff($scope.Staff);
			$scope.CreateStaffPromise.then(function (output) {
				NotificationService.alert('Staff Created', 'Staff was successfully created.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Staff Creation Failed', 'There was an error creating a new staff, please check the data and try again.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('EditStaffController', ["$scope", "$rootScope", "$modal", "$modalInstance", "StaffService", "MaintenanceService", "NotificationService", "Staff", "AreaManagerList", "DepartmentPickList", "StaffRolesList", "StaffGroupsList", function ($scope, $rootScope, $modal, $modalInstance, StaffService, MaintenanceService, NotificationService, Staff, AreaManagerList, DepartmentPickList, StaffRolesList,StaffGroupsList) {
		$scope.Staff = angular.copy(Staff);
		// $scope.Staff.AreaManager = $scope.Staff.AreaManager.Id != "00000000-0000-0000-0000-000000000000" ? $scope.Staff.AreaManager.Id : "";
		if ($scope.Staff.Department != null)
			$scope.Staff.Department = $scope.Staff.Department.Id;
		var roles = [];
		var groups = [];
		$scope.Staff.Roles.forEach(function (role) {
			roles.push(role.Id);
		});
		$scope.Staff.Groups.forEach(function (group) {
			groups.push(group.Id);
		});
		$scope.Staff.Roles = roles;
		$scope.Staff.Groups = groups;
		$scope.Today = new Date();
		$scope.openPopups = {};
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.AreaManagerList = angular.copy(AreaManagerList);
		for (var i = $scope.AreaManagerList.length - 1; i >= 0; i--) {
			if ($scope.AreaManagerList[i].Id == $scope.Staff.Id)
				$scope.AreaManagerList.splice(i, 1);
		}
		$scope.DepartmentPickList = DepartmentPickList;
		$scope.StaffRolesList = StaffRolesList;
		$scope.StaffGroupsList = StaffGroupsList;
		$scope.save = function () {
			// $scope.Staff.Username = $scope.Staff.Email;
			$scope.CreateStaffPromise = StaffService.editStaff($scope.Staff);
			$scope.CreateStaffPromise.then(function (output) {
				NotificationService.alert('Staff Details Changed', 'Staff details was successfully changed.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Staff Change Failed', 'There was an error editing the staff details, please check the data and try again.', 'error', {});

			});
		};

		$scope.openLinkModel = function () {
			openModal('views/modal-templates/account-link-to-cloud.html', 'LinkAccount', {
				UserId: $scope.Staff.Id,
				FirstName: $scope.Staff.FirstName,
				LastName: $scope.Staff.LastName
			});
		};

		$scope.removeLink = function () {
			if ($scope.Link = true) { // remove link
				var data = {
					UserId: $scope.Staff.Id
				}
				$scope.UnlinkPromise = MaintenanceService.UnlinkCloudDetails(data);
				$scope.UnlinkPromise.then(function (output) {
					if (output.data == "false") {
						NotificationService.alert('Unlinking failed', 'There was an error unlinking the current user from their cloud storage account, please try again later.', 'error', {});
					}
					else if (output.data == "true") {
						NotificationService.alert('Unlinked Account', 'The user has had the attached cloud storage account removed.', 'success', {})
						if ($rootScope.User.Id == $scope.Staff.Id) { // if the current user is revoking their own access, it should remove the current token they have been issued.
							$rootScope.User.Id = "";
						}
						$modalInstance.close();
					}
				});
			}
			else {
				NotificationService.Alert('User not linked to Cloud', 'This user has not been linked with a cloud storage account.', 'error', {});
			}
		}

		function openModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};

		$scope.cancel = function () {
			$modalInstance.close();
		};
	}])
	.controller('MaintenancePicklistController', ["$scope", "$modal", "$filter", "PickListService", "NotificationService", function ($scope, $modal, $filter, PickListService, NotificationService) {
		$scope.CurrentPicklist = {};

		$scope.init = function () {
			$scope.lodeDone = false;
			$scope.PickListNamePromise = PickListService.getPicklistNames();
			$scope.PickListNamePromise.then(function (output) {
				$scope.PickListNameList = output.data;
			});
		};

		/* 		$scope.startAt = 0;
				$scope.limit = 10;

				$scope.checkboxModel = {
					value: false,
				};

				$scope.includeDelete = function (selectedValue) {
					if (selectedValue) {
						$scope.getPicklists(true);
					} else {
						$scope.getPicklists();
					}
				}



				$scope.$watch('startAt', function () {
					$scope.getPicklists();
				});
		 */
		$scope.toggleDeleted = function () {
			$scope.AllowDelete = !$scope.CurrentPicklist.IsDeleted;
		}
		$scope.deletePicklist = function (col) {
			if (confirm("Are you sure you want to delete this picklist?")) {
				$scope.DeletePicklistPromise = PickListService.deletePicklist(col.Id);
				$scope.DeletePicklistPromise.then(function (output) {
					$scope.getPicklists($scope.CurrentPicklistName);
					NotificationService.alert('Picklist Deleted', 'The picklist was deleted successfully.', 'success', {});
				}, function (error) {
					NotificationService.alert('Picklist Delete Error', 'There was an error deleting.', 'error', {});

				});
			}
		};
		$scope.unDeletePicklist = function (col) {
			if (confirm("Are you sure you want to un-delete this picklist?")) {
				$scope.unDeletePicklistPromise = PickListService.unDeletePicklist(col.Id);
				$scope.unDeletePicklistPromise.then(function (output) {
					$scope.getPicklists($scope.CurrentPicklistName);
					NotificationService.alert('Picklist Un-Deleted', 'The picklist was un-deleted successfully.', 'success', {});
				}, function (error) {
					NotificationService.alert('Picklist UnDelete Error', 'There was an error un-deleting.', 'error', {});

				});
			}
		};
		$scope.getPicklists = function (data) {
			$scope.GetPicklistPromise = PickListService.getPickListByPickListName([data]);
			$scope.GetPicklistPromise.then(function (output) {
				$scope.picklist = output.data.PickListEntries;
				$scope.lodeDone = true;
				/*
 				$scope.picklistNextPage = output.data.PaginationStatus.HasPageForwards;
				$scope.ParametersPreviousPage = output.data.PaginationStatus.HasPageBack; */
			}, function (error) {
				NotificationService.alert('Parameter Error', 'There was an error retriving parameter.', 'error', {});
				$scope.PicklistError = true;
			})
		};

		$scope.filterPickList = function (value) {
			//$scope.picklist = $filter()
		}

		$scope.openPicklistEditModal = function (picklist) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editPicklistModal.html',
				controller: 'EditPicklistController',
				resolve: {
					Picklist: function () {
						return angular.copy(picklist);
					},
					PickListNameList: function () {
						return $scope.PickListNameList;
					}
				}
			});
			modalInstance.result.then(function () {
				$scope.getPicklists($scope.CurrentPicklistName);
			});
		};

		$scope.openPicklistCreateModal = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'createNewPicklistModal.html',
				controller: 'CreateNewPicklistController',
				resolve: {
					PickListNameList: function () {
						return $scope.PickListNameList;
					},
					CurrentPicklist: function () {
						return $scope.CurrentPicklistName;
					}
				}
			});
			modalInstance.result.then(function () {
				$scope.getPicklists($scope.CurrentPicklistName);
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};


	}])

	.controller('EditPicklistController', ["$scope", "$modalInstance", "PickListService", "NotificationService", "Picklist", "PickListNameList", function ($scope, $modalInstance, PickListService, NotificationService, Picklist, PickListNameList) {
		$scope.Picklist = Picklist;
		$scope.PickListNameList = PickListNameList;


		$scope.save = function () {
			var data = $scope.Picklist;
			$scope.EditPicklistPromise = PickListService.updatePicklist(data);
			$scope.EditPicklistPromise.then(function (output) {
				NotificationService.alert('Picklist Edited', 'The picklist was changed successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Picklist Edit Error', 'There was an error saving picklist changes.', 'error', {});
			});
		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};

	}])

	.controller('CreateNewPicklistController', ["$scope", "$modalInstance", "PickListService", "NotificationService", "PickListNameList", "CurrentPicklist", function ($scope, $modalInstance, PickListService, NotificationService, PickListNameList, CurrentPicklist) {
		$scope.PickListNameList = PickListNameList;
		$scope.Picklist = {};
		$scope.Picklist.PickListName = CurrentPicklist;
		/* $scope.Mode = 'new'
		$scope.NewPicklist = function () {
			$scope.AddNewPicklist = !$scope.AddNewPicklist;
			if ($scope.AddNewPicklist) {
				$scope.Mode = 'To Existing';
			} else {
				$scope.Mode = 'New'
			}
		} */

		$scope.create = function () {
			var data = $scope.Picklist;

			$scope.EditPicklistPromise = PickListService.createPicklist(data);
			$scope.EditPicklistPromise.then(function (output) {

				NotificationService.alert('Picklist Edited', 'The picklist was changed successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Picklist Edit Error', 'There was an error saving picklist changes.', 'error', {});
			});
		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};

	}])

	.controller('MaintenanceSourceDetailController', ["$scope", "$modal", "PickListService", function ($scope, $modal, PickListService) {

		$scope.$watch('startAt', function () {
			$scope.getSourceDetails();
		});

		$scope.startAt = 1;
		$scope.limit = 10;
		$scope.sourceDetailNextPage = true;

		$scope.getSourceDetails = function () {
			$scope.SourceDetailRequest = {};
			$scope.SourceDetailRequest.Pagination = {};
			$scope.SourceDetailRequest.Pagination.StartPage = $scope.startAt;
			$scope.SourceDetailRequest.Pagination.PageSize = $scope.limit;
			$scope.SourceDetailRequest.PickListNames = ['leadCode'];
			$scope.GetSourcePickListPromise = PickListService.getPaginatedPickListByPickListName($scope.SourceDetailRequest);
			$scope.GetSourcePickListPromise.then(function (output) {
				$scope.sourceDetail = output.data.PickListEntries;
				$scope.PaginationStatus = output.data.PaginationStatus;
			});
		}

		$scope.deletePicklist = function (col) {
			if (confirm("Are you sure you want to delete this picklist?")) {
				$scope.DeletePicklistPromise = PickListService.deletePicklist(col.Id);
				$scope.DeletePicklistPromise.then(function (output) {
					$scope.getPicklists($scope.CurrentPicklistName);
					NotificationService.alert('Picklist Deleted', 'The picklist was deleted successfully.', 'success', {});
				}, function (error) {
					NotificationService.alert('Picklist Delete Error', 'There was an error deleting.', 'error', {});

				});
			}
		};

		$scope.newQuoteOption = {};

		$scope.openSourceDetailEditModal = function (param) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editSourceDetailModal.html',
				controller: 'EditSourceDetailController',
				resolve: {
					SourceDetail: function () {
						return param;
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.getSourceDetails();
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

	}])

	.controller('PricingAppController', ["$scope", "$sce", "$modal", "PricingAppService", "$http", function ($scope, $sce, $modal, PricingAppService, $http) {

		$scope.$watch('startAt', function () {
			$scope.getPricingAppPage();
			
		});

		$scope.initPageType = function (page, id) {
			if (page && id) {
				$scope.pageType = `${page}/${id}`;
			} else {
				console.error('Page reference or LeadId is missing');
			}
		};

		$scope.getPricingAppPage = function () {

			let endpoint = $scope.pageType;

			PricingAppService.getConfigValue('PortalUrl').then(function (response) {

				let portalUrl = response.data.replace(/"/g, "");

				PricingAppService.getConfigValue('PortalCredentials').then(function (creds) {
				
					// Get Auth Token
					const authApiUrl = portalUrl + "api/auths";
					var portalCredentials = creds.data.replace(/"/g, '').split('|');
					const credentials = {
						username: portalCredentials[0],
						password: portalCredentials[1]
					};

					$http.post(authApiUrl, credentials)
						.then(function (response) {
							const token = response.data.token; // Get the JWT token

							var pageUrl = portalUrl + endpoint;

							// Append the token as a URL parameter
							pageUrl += '?sbtoken=' + encodeURIComponent(token);

							// Trust the URL, and use the pageUrl
							$scope.iframeSrc = $sce.trustAsResourceUrl(pageUrl);
						})
						.catch(function (error) {
							console.error("Authentication failed", error);
					});
				});
			});
		}
	}])

	.controller('EditSourceDetailController', ["$scope", "SourceDetail", "PickListService", "NotificationService", "$modalInstance", function ($scope, SourceDetail, PickListService, NotificationService, $modalInstance) {

		$scope.init = function () {
			$scope.SourceDetail = angular.copy(SourceDetail);
			getPicklist();
		};

		function getPicklist() {
			$scope.gettingParamsPromise = PickListService.getPickListByPickListName(['LeadSource']);
			$scope.gettingParamsPromise.then(function (output) {
				$scope.PickList = output.data.PickListEntries;
			}, function (error) {
				NotificationService.alert('Parameters Error', 'There was an error getting the parameters data.', 'error', {});
			});
		};

		$scope.save = function () {
			var data = $scope.SourceDetail;
			$scope.SourceDetailPromise = PickListService.updatePicklist(data);
			$scope.SourceDetailPromise.then(function (output) {
				NotificationService.alert('SourceDetail Edited', 'The source detail was changed successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('SourceDetail Edit Error', 'There was an error saving source detail changes.', 'error', {});
			});

		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};

	}])

	.controller('MaintenanceProductTypesController', ["$scope", "$modal", "PickListService", function ($scope, $modal, PickListService) {

		$scope.$watch('startAt', function () {
			$scope.getProductTypeDetails();
		});

		$scope.startAt = 1;
		$scope.limit = 10;
		$scope.productTypesNextPage = true;
		$scope.ParentTypes = [];

		$scope.getProductTypeDetails = function () {
			$scope.ProductTypeRequest = {};
			$scope.ProductTypeRequest.Pagination = {};
			$scope.ProductTypeRequest.Pagination.StartPage = $scope.startAt;
			$scope.ProductTypeRequest.Pagination.PageSize = $scope.limit;
			$scope.ProductTypeRequest.PickListNames = ['blindUse', 'BlindType', 'ParentEntries'];
			//$scope.ProductTypeRequest.PickListNameTwo = 'blindType';
			$scope.GetProductTypePickListPromise = PickListService.getPaginatedPickListByPickListName($scope.ProductTypeRequest);
			$scope.GetProductTypePickListPromise.then(function (output) {
				$scope.productTypes = output.data.PickListEntries;
				$scope.ParentTypes = output.data.ParentTypesObj.filter(parent => parent != null);
				$scope.PaginationStatus = output.data.PaginationStatus;
			});
		}

		$scope.openProductTypesEditModal = function (param) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editProductTypesModal.html',
				controller: 'EditProductTypeController',
				resolve: {
					ProductType: function () {
						return param;
					},
					ParentTypes: function () {
						return $scope.ParentTypes;
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.getProductTypeDetails();
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

	}])

	.controller('EditProductTypeController', ["$scope", "ProductType", "ParentTypes", "PickListService", "NotificationService", "$modalInstance", function ($scope, ProductType, ParentTypes, PickListService, NotificationService, $modalInstance) {
		$scope.ProductType = angular.copy(ProductType);
		$scope.ParentTypes = ParentTypes;

		$scope.save = function () {
			var data = $scope.ProductType;
			$scope.ProductTypePromise = PickListService.updatePicklist(data);
			$scope.ProductTypePromise.then(function (output) {
				NotificationService.alert('Product Type Edited', 'The product type was changed successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Product Type Edit Error', 'There was an error saving product type changes.', 'error', {});
			});

		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};

	}])

	.controller('MaintenanceOutcodeController', ["$scope", "$rootScope", "$modal", "MaintenanceService", "NotificationService", "ParamService", function ($scope, $rootScope, $modal, MaintenanceService, NotificationService, ParamService) {

		$scope.init = function () {
			$scope.GetCoveragePickListPromise = ParamService.getParamByEntityAndField('District', 'Coverage');
			$scope.GetCoveragePickListPromise.then(function (output) {
				$scope.CoveragePickList = output.data.Params;
			});
		};

		$scope.$watch('startAt', function () {
			$scope.getDistrict();
		});

		$scope.startAt = 0;
		$scope.limit = 10;

		$scope.getDistrict = function () {
			$scope.District = {};
			$scope.District.Pagination = {};
			$scope.District.Pagination.StartPage = $scope.startAt;
			$scope.District.Pagination.PageSize = $scope.limit;
			$scope.GetDistrictPromise = MaintenanceService.getDistrict($scope.District);
			$scope.GetDistrictPromise.then(function (output) {
				$scope.districts = output.data.DistrictList;
				$scope.PaginationStatus = output.data.PaginationStatus;

			}, function (error) {
				NotificationService.alert('Web Enquiry Error', 'There was an error retriving the unprocessed web enquiries.', 'error', {});
			});
		};

		$scope.deleteDistrict = function (col) {
			if (confirm("Are you sure you want to delete this district?")) {
				$scope.DeleteDistrictPromise = MaintenanceService.deleteDistrict(col.Id);
				$scope.DeleteDistrictPromise.then(function (output) {
					$scope.getDistrict();
					NotificationService.alert('District Deleted', 'The district was deleted successfully.', 'success', {});
				}, function (error) {
					NotificationService.alert('District Delete Error', 'There was an error deleting.', 'error', {});
				});
			}
		}

		$scope.districtsPage = 1;
		$scope.districtsNextPage = true;

		$scope.openDistrictEditModal = function (district) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editDistrictModal.html',
				controller: 'EditMaintenanceOutcodeController',
				resolve: {
					District: function () {
						return district;
					},
					CoveragePickList: function () {
						return $scope.CoveragePickList;
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.getDistrict();
			});
		};

		$scope.openDistrictCreateModal = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'createDistrictModal.html',
				controller: 'CreateMaintenanceOutcodeController',
				resolve: {
					CoveragePickList: function () {
						return $scope.CoveragePickList;
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.getDistrict();
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

	}])
	.controller('EditMaintenanceOutcodeController', ["$scope", "$rootScope", "$modal", "$modalInstance", "MaintenanceService", "NotificationService", "CoveragePickList", "District", function ($scope, $rootScope, $modal, $modalInstance, MaintenanceService, NotificationService, CoveragePickList, District) {
		$scope.CoveragePickList = CoveragePickList;
		$scope.District = District;
		$scope.District.Coverage = $scope.District.Coverage != null ? $scope.District.Coverage.Id : null;

		$scope.save = function () {
			var data = $scope.District;
			$scope.EditDistrictPromise = MaintenanceService.updateDistrict(data);
			$scope.EditDistrictPromise.then(function (output) {
				NotificationService.alert('District Edited', 'The district was changed successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('District Edit Error', 'There was an error saving district changes.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('CreateMaintenanceOutcodeController', ["$scope", "$rootScope", "$modal", "$modalInstance", "MaintenanceService", "NotificationService", "CoveragePickList", function ($scope, $rootScope, $modal, $modalInstance, MaintenanceService, NotificationService, CoveragePickList) {
		$scope.CoveragePickList = CoveragePickList;
		$scope.District = {};
		$scope.save = function () {
			var data = $scope.District;
			$scope.CreateDistrictPromise = MaintenanceService.createDistrict(data);
			$scope.CreateDistrictPromise.then(function (output) {
				NotificationService.alert('District Created', 'The district was created successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('District Creation Error', 'There was an error creating a district.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('MaintenanceSuppliersController', ["$scope", "$modal", function ($scope, $modal) {

		$scope.suppliers = [];

		$scope.suppliersPage = 1;
		$scope.suppliersNextPage = true;

		$scope.openSuppliersEditModal = function (param) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editSuppliersModal.html',
				//controller: '',
				resolve: {}
			});
		};

		$scope.openSupplierCreateModal = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'createNewSuppliersModal.html',
				//controller: '',
				resolve: {}
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

	}])
	.controller('EditMaintenanceSuppliersController', ["$scope", "$rootScope", "$modal", "$modalInstance", "MaintenanceService", "NotificationService", "Supplier", function ($scope, $rootScope, $modal, $modalInstance, MaintenanceService, NotificationService, Supplier) {
		$scope.Supplier = Supplier;

		$scope.save = function () {
			var data = $scope.Supplier;
			$scope.EditSupplierPromise = MaintenanceService.updateSupplier(data);
			$scope.EditSupplierPromise.then(function (output) {
				NotificationService.alert('Supplier Edited', 'The supplier was changed successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Supplier Edit Error', 'There was an error saving supplier changes.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('CreateMaintenanceSuppliersController', ["$scope", "$rootScope", "$modal", "$modalInstance", "MaintenanceService", "NotificationService", function ($scope, $rootScope, $modal, $modalInstance, MaintenanceService, NotificationService) {
		$scope.Supplier = {};
		$scope.save = function () {
			var data = $scope.District;
			$scope.CreateSupplierPromise = MaintenanceService.createSupplier(data);
			$scope.CreateSupplierPromise.then(function (output) {
				NotificationService.alert('Supplier Created', 'The supplier was created successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Supplier Creation Error', 'There was an error creating a supplier.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('MaintenanceUserGroupsController', ["$scope", "$rootScope", "$modal", "MaintenanceService", "NotificationService", function ($scope, $rootScope, $modal, MaintenanceService, NotificationService) {
		$scope.$watch('startAt', function () {
			$scope.getUserGroups();
		});

		$scope.startAt = 0;
		$scope.limit = 100;

		$scope.getUserGroups = function () {
			$scope.GlobalUserGroupError = false;
			var data = {};
			data.Pagination = {};
			data.Pagination.StartPage = $scope.startAt;
			data.Pagination.PageSize = $scope.limit;
			$scope.GetUserGroupsPromise = MaintenanceService.getUserGroups(data);
			$scope.GetUserGroupsPromise.then(function (output) {
				$scope.UserGroups = output.data.UserGroupList;

				$scope.ParametersNextPage = output.data.PaginationStatus.HasPageForwards;
				$scope.ParametersPreviousPage = output.data.PaginationStatus.HasPageBack;
			}, function (error) {
				NotificationService.alert('User Group Error', 'There was an error retriving user groups.', 'error', {});
				$scope.GlobaUserGroupError = true;
			})
		};

		$scope.openUserGroupModal = function (group) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editUserGroupModal.html',
				controller: 'EditUserGroupController',
				resolve: {
					group: function () {
						return group;
					},
				}
			});

			modalInstance.result.then(function () {
				$scope.getGlobalParams();
			});
		};

		$scope.deleteUserGroup = function (group) {
			if (confirm("Are you sure that you want to delete this usergroup?")) {
				$scope.DeleteUserGroupPromise = MaintenanceService.deleteUserGroup(group.Id);
				$scope.DeleteUserGroupPromise.then(function (output) {
					NotificationService.alert('Group Deleted', 'The group was deleted successfully.', 'success', {});
					$scope.getUserGroups();
				}, function (error) {
					NotificationService.alert('Group Delete Error', 'There was an error deleting the user group.', 'error', {});
				});
			}
		}
		$scope.openGroupCreateModal = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'createNewGroupModal.html',
				controller: 'CreateNewUserGroupController'
			});
			modalInstance.result.then(function () {
				$scope.getUserGroups();
			});
		};
	
		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

	}])
	.controller('CreateNewUserGroupController', ["$scope", "$rootScope", "$modal", "$modalInstance", "MaintenanceService", "NotificationService", function ($scope, $rootScope, $modal, $modalInstance, MaintenanceService, NotificationService) {
			$scope.Group = {};

			$scope.create = function () {
				$scope.CreateUserGroupPromise = MaintenanceService.updateUserGroup($scope.Group);
				$scope.CreateUserGroupPromise.then(function (output) {
					NotificationService.alert('User Group Created', 'Group was successfully created.', 'success', {});
					$modalInstance.close();
				}, function (error) {
					NotificationService.alert('User Group Creation Failed', 'There was an error creating a new user group, please check the data and try again.', 'error', {});
				});
			};

			$scope.cancel = function () {
				$modalInstance.dismiss('cancel');
			};
		}])
	.controller('EditUserGroupController', ["$scope", "$rootScope", "group", "MaintenanceService", "$modalInstance", "NotificationService", function ($scope, $rootScope, group, MaintenanceService, $modalInstance, NotificationService) {
		$scope.editGroup = angular.copy(group);
		$scope.GetGroupPromise = MaintenanceService.getGroup($scope.editGroup.Id);
		$scope.GetGroupPromise.then(function (output) {
			$scope.StaffList = output.data.StaffList;
		}, function (error) {
			NotificationService.alert('Group Updated Error', 'There was an error updating the group, please try again.', 'error', {});
		});

		$scope.save = function () {
		

			$scope.UpdateGroupPromise = MaintenanceService.updateUserGroup($scope.editGroup);
			$scope.UpdateGroupPromise.then(function (output) {
				NotificationService.alert('Group Updated', 'The group was updated successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Group Updated Error', 'There was an error updating the group, please try again.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('MaintenanceQuoteOptionsController', ["$scope", "$rootScope", "$modal", "MaintenanceService", "NotificationService", function ($scope, $rootScope, $modal, MaintenanceService, NotificationService) {
		$scope.$watch('startAt', function () {
			$scope.getQuoteOptions();
		});

		$scope.startAt = 0;
		$scope.limit = 100;

		$scope.getQuoteOptions = function () {
			$scope.QuoteOptionError = false;
			var data = {};
			data.Pagination = {};
			data.Pagination.StartPage = $scope.startAt;
			data.Pagination.PageSize = $scope.limit;
			$scope.GetQuoteOptionsPromise = MaintenanceService.getQuoteOptions(data);
			$scope.GetQuoteOptionsPromise.then(function (output) {
				$scope.QuoteOptions = output.data.QuoteOptionList;

				$scope.QuoteOptionsNextPage = output.data.PaginationStatus.HasPageForwards;
				$scope.QuoteOptionsPreviousPage = output.data.PaginationStatus.HasPageBack;
			}, function (error) {
				NotificationService.alert('Quote Options Error', 'There was an error retriving quote options.', 'error', {});
				$scope.QuoteOptionError = true;
			})
		};

		$scope.deleteQuoteOption = function (quoteOption) {
			if (confirm("Are you sure that you want to delete this quote option?")) {
				$scope.DeleteQuoteOptionPromise = MaintenanceService.deleteQuoteOption(quoteOption.Id);
				$scope.DeleteQuoteOptionPromise.then(function (output) {
					NotificationService.alert('Quote Option Deleted', 'The quote option was deleted successfully.', 'success', {});
					$scope.getQuoteOptions();
				}, function (error) {
					NotificationService.alert('Quote Option Delete Error', 'There was an error deleting the quote option.', 'error', {});
				});
			}
		}

		$scope.newQuoteOption = {};

		$scope.openQuoteOptionModal = function (quoteOption) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editQuoteOptionModal.html',
				controller: 'EditQuoteOptionController',
				resolve: {
					quoteOption: function () {
						return quoteOption;
					},
				}
			});

			modalInstance.result.then(function () {
				$scope.getQuoteOptions();
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

	}])
	.controller('EditQuoteOptionController', ["$scope", "$rootScope", "quoteOption", "MaintenanceService", "$modalInstance", "NotificationService", function ($scope, $rootScope, quoteOption, MaintenanceService, $modalInstance, NotificationService) {
		$scope.editQuoteOption = angular.copy(quoteOption);

		// Add or Edit?
		if ($scope.editQuoteOption.Id == undefined)
			$scope.mode = "Add";
		else
			$scope.mode = "Edit";

		$scope.save = function () {

			if ($scope.editQuoteOption.Id == undefined) {

				var data = {};
				$scope.mode = "Add";
				data.Id = 0;
				data.Code = $scope.editQuoteOption.Code;
				data.Type = $scope.editQuoteOption.Type;
				data.Label = $scope.editQuoteOption.Label;
				data.Description = $scope.editQuoteOption.Description;

				$scope.GetQuoteOptionPromise = MaintenanceService.addQuoteOption(data);
				$scope.GetQuoteOptionPromise.then(function (output) {
					NotificationService.alert('Quote Option Updated', 'The quote option was added successfully.', 'success', {});
					$modalInstance.close();
				}, function (error) {
					NotificationService.alert('Quote Option Updated Error', 'There was an error adding the quote option, please try again.', 'error', {});
				});
			}
			else {
				var data = {};
				$scope.mode = "Edit";
				data.Id = $scope.editQuoteOption.Id;
				data.Code = $scope.editQuoteOption.Code;
				data.Type = $scope.editQuoteOption.Type;
				data.Label = $scope.editQuoteOption.Label;
				data.Description = $scope.editQuoteOption.Description;

				$scope.GetQuoteOptionPromise = MaintenanceService.updateQuoteOption(data);
				$scope.GetQuoteOptionPromise.then(function (output) {
					NotificationService.alert('Quote Option Updated', 'The quote option was updated successfully.', 'success', {});
					$modalInstance.close();
				}, function (error) {
					NotificationService.alert('Quote Option Updated Error', 'There was an error updating the quote option, please try again.', 'error', {});
				});
			}


		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('MaintenancePricingCalculatorController', ["$scope", "$rootScope", "$modal", "MaintenanceService", "NotificationService", "PricingAppService", function ($scope, $rootScope, $modal, MaintenanceService, NotificationService, PricingAppService) {

		$scope.redirectToPricingMaintenance = function (type) {
			PricingAppService.getConfigValue('PortalUrl').then(function (response) {
				PricingAppService.getConfigValue('PortalCredentials').then(function (response2) {
					let portalUrl = response.data;
					let portalCredentials = response2.data;
					var data = { type, portalUrl, portalCredentials }
					openPricingAppModal('views/modal-templates/iframe.html', 'IFrameController', data);
					});
			});
		};

		function openPricingAppModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				templateUrl: modalTemplate,
				controller: Controller,
				windowClass: 'xlarge-Modal',
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};

	}])
